var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-images position-relative"},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"img-fluid lazyload",staticStyle:{"width":"100%","height":"auto"},attrs:{"data-src":_vm.videoInfo.image,"alt":""}})]),_c('div',{staticClass:"block-description"},[_c('h6',{staticClass:"block-description__title"},[_c('router-link',{attrs:{"to":{
        name: 'landing-page.category-detail',
        query: { movieId: _vm.videoInfo.id },
      }}},[_vm._v(_vm._s(_vm.videoInfo.title)+" ")])],1),(_vm.showDate)?_c('div',{staticClass:"d-flex publication-time"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.videoInfo.date))])]):_vm._e(),(_vm.showVisualizations)?_c('div',{staticClass:"d-flex views"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.videoInfo.vistas[0].visualizations)+" veces visto")])]):_vm._e(),_c('div',{staticClass:"movie-time d-flex align-items-center my-2"},[_c('div',{staticClass:"badge badge-secondary p-1 mr-2"},[_vm._v(" "+_vm._s(_vm.videoInfo.resolution)+" ")]),_c('span',{staticClass:"text-white font-size-span"},[_vm._v(_vm._s(_vm.videoInfo.time))])]),_c('div',{staticClass:"hover-buttons"},[_c('router-link',{staticClass:"btn btn-hover",attrs:{"to":{
        name: 'landing-page.category-detail',
        query: { movieId: _vm.videoInfo.id },
      }}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}}),_vm._v(" REPRODUCIR ")])],1),_c('div')])])
}
var staticRenderFns = []

export { render, staticRenderFns }