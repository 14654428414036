<template>
  <div>
    <Category
      v-for="(result, index) in Category"
      :key="index"
      :categoryTitle="result.categoryTitle"
      :episodes="result.episodes"
    />
  </div>
</template>
<script>
import { core } from "../../config/pluginInit"
import Category from "./Components/Category/Category"
export default {
  name: "MainPage",
  components: {
    Category
  },
  data() {
    return {
      Category: [
        {
          categoryTitle: "Lo más visto",
          episodes: ["0", "1", "2", "3", "4", "5", "6", "7"]
        },
        {
          categoryTitle: "¿Qué estudiar?",
          episodes: [
            "2",
            "6",
            "3",
            "7",
            "34",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33"
          ]
        },
        {
          categoryTitle: "Así se vive la U",
          episodes: ["3", "1", "5", "7"]
        },
        {
          categoryTitle: "Oportunidades para ti",
          episodes: ["4", "6", "7", "5"]
        },
        {
          categoryTitle: "En el Caribe me quedo",
          episodes: ["0", "1", "7"]
        },
        {
          categoryTitle:
            "Para los que les gustan los idiomas, la cultura y las relaciones globales",
          episodes: ["16", "33", "9", "15"]
        },
        {
          categoryTitle:
            "Si te gusta el diseño, la creación, innovación, la imagen y la comunicación",
          episodes: ["12", "23", "13", "11", "18"]
        },
        {
          categoryTitle:
            "Pasión por el mundo digital, sistemas, datos, tecnología y toma de decisiones",
          episodes: ["29", "20", "12", "27", "19"]
        },
        {
          categoryTitle:
            "Los que quieren emprender, gerenciar, abrir nuevos mercados",
          episodes: ["24", "19", "9", "21", "20", "30", "13"]
        },
        {
          categoryTitle: "Si te identificas con la salud y el bienestar",
          episodes: ["31", "17", "14", "32", "25"]
        },
        {
          categoryTitle:
            "Si lo tuyo es la argumentación, el debate, la vocería y las relaciones humanas",
          episodes: ["34", "22", "12", "15", "16"]
        },
        {
          categoryTitle:
            "Para los preocupados por el medio ambiente y la sostenibilidad",
          episodes: ["26", "8", "27", "10", "28", "11"]
        },
        {
          categoryTitle:
            "¿Interesado por la política y el impacto social?. Esto es para ti.",
          episodes: ["22", "34", "25", "24", "32", "12"]
        },
        {
          categoryTitle: "¿No sabes qué estudiar?",
          episodes: [
            "19",
            "11",
            "20",
            "34",
            "12",
            "21",
            "22",
            "23",
            "13",
            "24",
            "14",
            "15",
            "8",
            "10",
            "26",
            "27",
            "28",
            "29",
            "16",
            "25",
            "18",
            "30",
            "31",
            "9",
            "17",
            "32",
            "33"
          ]
        }
      ]
    }
  },
  mounted() {
    core.index()
  }
}
</script>
