<template>
  <section id="iq-suggestede" class="s-margin">
    <b-container fluid>
      <b-row>
        <b-col sm="12" class="overflow-hidden">
          <div
            class="iq-main-header d-flex align-items-center justify-content-between"
          >
            <h4 class="main-title" v-b-tooltip.hover title="">
              {{ categoryTitle }}
            </h4>
          </div>
          <div class="suggestede-contens">
            <Slick
              v-if="categoryData.length > 0"
              class="list-inline favorites-slider row p-0 mb-0"
              ref="dSlick"
              :option="categorySliderOption"
            >
              <li
                class="slide-item"
                v-for="(item, index) in categoryData"
                :key="index"
              >
                <VideoSlide :videoInfo="item"></VideoSlide>
              </li>
            </Slick>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import firebase from "firebase/app"
import "firebase/firestore"
import VideoSlide from "../../../../components/core/custom/VideoSlide.vue"

const db = firebase.firestore()

export default {
  name: "Category",
  props: {
    categoryTitle: String,
    episodes: Array,
    ordered: Boolean
  },
  components: {
    VideoSlide
  },
  mounted() {
    for (const key in this.episodes) {
      this.obtenerDocumento("videos", this.episodes[key], this.categoryData)
    }
  },
  data() {
    return {
      categoryData: [],
      categorySliderOption: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow:
          '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
        nextArrow:
          '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  methods: {
    next() {
      this.$refs.dSlick.next()
    },
    prev() {
      this.$refs.dSlick.prev()
    },
    obtenerDocumento(collection, idvideos, arr) {
      idvideos = idvideos.trim()
      db.collection(collection)
        .where("id", "==", idvideos)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const vistasRef = doc.ref.collection("vistas")

            vistasRef.get().then((vistasSnapshot) => {
              const vistasData = []
              vistasSnapshot.forEach((vistaDoc) => {
                vistasData.push(vistaDoc.data())
              })

              const videoData = {
                ...doc.data(),
                vistas: vistasData
              }

              arr.push(videoData)
            })
          })
        })
    }
  }
}
</script>
