<template>
  <div class="block-images position-relative">
    <div class="img-box">
      <img :data-src="videoInfo.image" class="img-fluid lazyload" alt="" style="width: 100%; height: auto;" />
    </div>
    <div class="block-description">
      <h6 class="block-description__title">
        <router-link :to="{
          name: 'landing-page.category-detail',
          query: { movieId: videoInfo.id },
        }">{{ videoInfo.title }}
        </router-link>
      </h6>
      <div class="d-flex publication-time" v-if="showDate">
        <span class="text-white">{{ videoInfo.date }}</span>
      </div>
      <div class="d-flex views" v-if="showVisualizations">
        <span class="text-white">{{ videoInfo.vistas[0].visualizations }} veces visto</span>
      </div>
      <div class="movie-time d-flex align-items-center my-2">
        <div class="badge badge-secondary p-1 mr-2">
          {{ videoInfo.resolution }}
        </div>
        <span class="text-white font-size-span">{{ videoInfo.time }}</span>

        <!--<button v-on:click="crearDocument">HOLAMUNDO</button>-->
      </div>
      <div class="hover-buttons">
        <router-link class="btn btn-hover" :to="{
          name: 'landing-page.category-detail',
          query: { movieId: videoInfo.id },
        }"><i class="fa fa-play mr-1" aria-hidden="true"></i>
          REPRODUCIR
        </router-link>
      </div>
      <div></div>
    </div>
  </div>
</template>
<script>
import dateUtilities from "../../../Utils/date-utilities.js"
import "firebase/firestore"

export default {
  name: "VideoSlide",
  props: {
    videoInfo: Object,
    showVisualizations: Boolean,
    showDate: Boolean
  },
  data() {
    return {
      visualizations: null
    }
  },
  mounted() {
    this.$set(
      this.videoInfo,
      "date",
      dateUtilities.firebaseDateParse(this.videoInfo.fecha)
    )
  }
}
</script>
<style scoped>
.block-description {
  width: 100%;
}

.block-description__title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 70%;
}
</style>
