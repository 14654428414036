export default {
  dateFormatOptions: {
    year: "numeric",
    month: "long",
    day: "numeric"
  },
  firebaseDateParse (firebaseDate) {
    const date = new Date(firebaseDate.toDate())
    return date.toLocaleDateString("es-ES", this.dateFormatOptions)
  }
}
